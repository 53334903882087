import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  Autocomplete,
  Box,
  Button,
  createFilterOptions,
  FormControl,
  Input,
  InputLabel,
  Select,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useContext, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { FieldOptions, InventoryItem, LogChanges } from "../types";
import { updateDocument } from "../database/write";
import { inventory_collection_name } from "../firebase/firebase";
import { AuthContext } from "../context/AuthContext";
import { NetworkContext } from "../context/NetworkContext";
import { logItemSources } from "../lib";

const Edit = () => {
  const { dbOffline } = useContext(NetworkContext);
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const {
    state,
  }: {
    state?: { item: InventoryItem; fieldOptions: FieldOptions };
  } = useLocation();
  const [searchParams] = useSearchParams();
  const { breakpoints } = useTheme();
  const smallScreen = useMediaQuery(breakpoints.down("sm"));
  const largeScreen = useMediaQuery(breakpoints.down("lg"));

  let item: any;
  let fieldOptions;
  if (state) {
    item = state.item;
    fieldOptions = state.fieldOptions;
  }

  interface ContainerOptionType {
    inputValue?: string;
    title: string;
  }

  const containerSelectorFilter = createFilterOptions<ContainerOptionType>();

  const containers: readonly ContainerOptionType[] =
    fieldOptions?.container?.map((container: string) => ({
      title: container,
    })) ?? [];

  const [name, setName] = useState(item?.name);
  const [sector, setSector] = useState(item?.sector);
  const [container, setContainer] = useState<ContainerOptionType | null>({
    title: item?.container ?? "",
  });
  const [category, setCategory] = useState(item?.category);
  const [amount, setAmount] = useState(item?.amount ?? "");
  const [unit, setUnit] = useState(item?.unit);
  const [size, setSize] = useState(item?.size ?? "");
  const [trait, setTrait] = useState(item?.trait ?? "");
  const [condition, setCondition] = useState(item?.condition);
  const [checked_out, setChecked_out] = useState(item?.checked_out);
  const [comment, setComment] = useState(item?.comment ?? "");

  if (!item || !fieldOptions) {
    return <Navigate to={searchParams.get("from") || "/"} replace={true} />;
  }

  const onSubmit = (e: any) => {
    e.preventDefault();
    const updateData: Omit<InventoryItem, "docId"> = {
      name,
      sector,
      container: container?.title ?? "",
      category,
      amount,
      unit,
      size,
      trait,
      condition,
      checked_out,
      comment,
    };
    let changes: LogChanges = [];
    if (item) {
      changes = Object.keys(updateData)
        .map((k) => ({
          field: k,
          old: item[k] === undefined ? "" : item[k],
          new: updateData[k as keyof Omit<InventoryItem, "docId">],
        }))
        .filter((k) => k.old !== k.new);
    }
    if (currentUser) {
      updateDocument(inventory_collection_name, item.docId, updateData, {
        source: logItemSources.log_src_edit,
        item_status: checked_out ? "out" : "in",
        user: currentUser,
        changes: changes,
      })
        .then(() => {
          navigate(
            {
              pathname: searchParams.get("from") || "/",
            },
            { replace: true }
          );
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "3vw",
      }}
    >
      <Typography
        marginBottom={smallScreen ? "3vh" : largeScreen ? "5vh" : "7vh"}
        variant="h1"
        fontSize={{ xs: 30, sm: 40, md: 50 }}
      >
        {item?.name} szerkesztése
      </Typography>
      <form
        onSubmit={onSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: "3vh",
          width: smallScreen ? "90%" : largeScreen ? "60%" : "40%",
        }}
      >
        <FormControl>
          <InputLabel htmlFor="name">Név</InputLabel>
          <Input
            id="name"
            required
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <InputLabel htmlFor="sector">Szektor</InputLabel>
          <Select
            defaultValue={item?.sector}
            id="sector"
            required
            name="sector"
            value={sector}
            onChange={(e) => setSector(e.target.value)}
          >
            {fieldOptions?.sector?.map((sector) => (
              <MenuItem key={sector} value={sector}>
                {sector}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <Autocomplete
            value={container}
            onChange={(event, newValue) => {
              if (typeof newValue === "string") {
                setContainer({
                  title: newValue,
                });
              } else if (newValue && newValue.inputValue) {
                // Create a new value from the user input
                setContainer({
                  title: newValue.inputValue,
                });
              } else {
                setContainer(newValue);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = containerSelectorFilter(options, params);

              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some(
                (option) => inputValue === option.title
              );
              if (inputValue !== "" && !isExisting) {
                filtered.push({
                  inputValue,
                  title: `Új opció: "${inputValue}"`,
                });
              }

              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="container"
            options={containers}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option.title;
            }}
            renderOption={(props, option) => <li {...props}>{option.title}</li>}
            sx={{ width: 300 }}
            freeSolo
            renderInput={(params) => <TextField {...params} label="Tároló" />}
          />
        </FormControl>
        <FormControl>
          <InputLabel htmlFor="category">Kategória</InputLabel>
          <Select
            defaultValue={item?.category}
            id="category"
            required
            name="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            {fieldOptions?.category?.map((category) => (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel htmlFor="amount">Mennyiség</InputLabel>
          <Input
            id="amount"
            name="amount"
            type="number"
            value={amount.toString()}
            onChange={(e) => setAmount(parseFloat(e.target.value))}
          />
        </FormControl>
        <FormControl>
          <InputLabel htmlFor="unit">Mértékegység</InputLabel>
          <Select
            defaultValue={item?.unit}
            id="unit"
            name="unit"
            value={unit}
            onChange={(e) => setUnit(e.target.value)}
          >
            {fieldOptions?.unit?.map((unit) => (
              <MenuItem key={unit} value={unit}>
                {unit}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel htmlFor="size">Méret</InputLabel>
          <Input
            id="size"
            name="size"
            value={size}
            onChange={(e) => setSize(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <InputLabel htmlFor="trait">Jellemző</InputLabel>
          <Input
            id="trait"
            name="trait"
            value={trait}
            onChange={(e) => setTrait(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <InputLabel htmlFor="condition">Állapot</InputLabel>
          <Select
            multiple
            defaultValue={item?.condition}
            id="condition"
            required
            name="condition"
            value={condition}
            onChange={(e) => {
              const {
                target: { value },
              } = e;
              setCondition(
                typeof value === "string" ? value.split(",") : value
              );
            }}
          >
            {fieldOptions?.condition?.map((condition) => (
              <MenuItem key={condition} value={condition}>
                {condition}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography>Bent</Typography>
          <Switch
            id="checked_out"
            name="checked_out"
            checked={checked_out as boolean}
            onChange={(e) => setChecked_out(e.target.checked)}
          />
          <Typography>Kint</Typography>
        </FormControl>
        <FormControl>
          <InputLabel htmlFor="comment">Megjegyzés</InputLabel>
          <Input
            multiline
            id="comment"
            name="comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </FormControl>

        <Button
          disabled={dbOffline}
          variant="contained"
          color="primary"
          type="submit"
        >
          Mentés
        </Button>
      </form>
    </Box>
  );
};

export default Edit;
