import { Autocomplete, Box, TextField } from "@mui/material";
import { InventoryItem } from "../types";

const Search = ({
  items,
  setScrollToIndex,
}: {
  items: InventoryItem[];
  setScrollToIndex: (index: number) => void;
}) => {
  const options = items.map((i) => ({
    id: i.docId,
    name: i.name,
    sector: i.sector,
  }));
  return (
    <Box>
      <Autocomplete
        id="search-inventory"
        options={options}
        renderOption={(props, option) => (
          <Box component="li" {...props} key={option.id}>
            {option.name} ({option.sector})
          </Box>
        )}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => {
          return option.id === value.id;
        }}
        renderInput={(params) => (
          <TextField key={params.id} {...params} label="Keresés" />
        )}
        onChange={(event, value) => {
          if (value) {
            const index = items.findIndex((i) => i.docId === value.id);
            if (index !== -1) {
              setScrollToIndex(index);
            }
          } else {
            setScrollToIndex(-1);
          }
        }}
      />
    </Box>
  );
};

export default Search;
