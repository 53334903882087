import {
  collection,
  FieldPath,
  getDocs,
  onSnapshot,
  query,
  where,
  WhereFilterOp,
} from "firebase/firestore";
import {
  db,
  helper_collection_name,
  inventory_collection_name,
} from "../firebase/firebase";

export const getAllFromCollection = async (
  collName: string = inventory_collection_name
) => {
  const documents: any[] = [];
  const querySnapshot = await getDocs(collection(db, collName));
  querySnapshot.forEach((doc) => {
    documents.push({ docId: doc.id, ...doc.data() });
  });
  return documents;
};

export const listenToCollection = (
  setItems: (items: any) => void,
  setDbLoading?: (loading: boolean) => void,
  setDbOffline?: (offline: boolean) => void,
  condition?: {
    fieldPath: string | FieldPath;
    opStr: WhereFilterOp;
    value: unknown;
  },
  collName: string = inventory_collection_name
) => {
  if (setDbLoading) {
    setDbLoading(true);
  }
  const queryConstraints: any[] = [];
  if (condition) {
    queryConstraints.push(
      where(condition.fieldPath, condition.opStr, condition.value)
    );
  }
  const q = query(collection(db, collName), ...queryConstraints);
  return onSnapshot(q, { includeMetadataChanges: true }, (querySnapshot) => {
    const items: any[] = [];
    querySnapshot.forEach((doc) => {
      let item;
      item = { docId: doc.id, ...doc.data() };
      // this is a hack to add amount_with_unit to the item, bad practice
      if (collName === inventory_collection_name && !isNaN(doc.data().amount)) {
        const amount_with_unit = doc.data().amount + " " + doc.data().unit;
        item = { docId: doc.id, ...doc.data(), amount_with_unit };
      }
      items.push(item);
    });
    // if the collection is the helper collection, we only want the first item for now
    setItems(collName === helper_collection_name ? items[0] : items);
    if (setDbOffline) {
      setDbOffline(querySnapshot.metadata.fromCache);
    }
    setTimeout(() => {
      if (setDbLoading) {
        setDbLoading(false);
      }
    }, 500);
  });
};
