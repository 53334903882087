import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import ListItems from "../pages/ListItems";
import Login from "../pages/Login";
import NotFound from "../pages/NotFound";
import React, { useContext } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { AuthContext } from "../context/AuthContext";
import { AuthenticatedRoute, UnauthenticatedRoute } from "./Routes";
import Edit from "../pages/Edit";
import Details from "../pages/Details";
import New from "../pages/New";

const RouterWithAuthProtection = () => {
  const { breakpoints } = useTheme();
  const { currentUser } = useContext(AuthContext);
  const smallScreen = useMediaQuery(breakpoints.down("md"));

  return (
    <Routes>
      <Route element={<AuthenticatedRoute user={currentUser} />}>
        <Route index path="/" element={<Home />} />
        <Route
          path="/list"
          element={
            <ListItems pageTitle="Minden tétel" smallScreen={smallScreen} />
          }
        />
        <Route
          path="/checked_out"
          element={
            <ListItems
              pageTitle="Kivett tételek"
              smallScreen={smallScreen}
              docFilters={{
                field: "checked_out",
                value: true,
              }}
            />
          }
        />
        <Route path="/details/:id" element={<Details />} />
        <Route path="/edit" element={<Edit />} />
        <Route path="/new" element={<New />} />
      </Route>
      <Route element={<UnauthenticatedRoute user={currentUser} />}>
        <Route path="/login" element={<Login />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default RouterWithAuthProtection;
