import { Box, Tab, Tabs } from "@mui/material";
import { SyntheticEvent, useContext, useState } from "react";
import { InventoryContext } from "../context/InventoryContext";
import ItemsListMobile from "./ItemsListMobile";

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const HomeTabs = () => {
  const { inventory, fieldOptions } = useContext(InventoryContext);
  const [value, setValue] = useState(0);
  const sectorNames = fieldOptions?.sector;

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ marginX: { xs: -2, sm: 0 } }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", margin: 2 }}>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={value}
          onChange={handleChange}
          aria-label="tabs for sectors"
        >
          {sectorNames?.map((sectorName: string, index: number) => (
            <Tab key={index} label={sectorName} {...a11yProps(index)} />
          ))}
        </Tabs>
      </Box>
      {sectorNames?.map((sectorName: string, index: number) => (
        <Box
          key={index}
          role="tabpanel"
          hidden={value !== index}
          id={`home-tabpanel-${index}`}
          aria-labelledby={`home-tab-${index}`}
          sx={{ padding: 2 }}
        >
          <ItemsListMobile
            items={inventory?.filter((item) => item.sector === sectorName)}
          />
        </Box>
      ))}
    </Box>
  );
};

export default HomeTabs;
