import { db, logging_collection_name } from "../firebase/firebase";
import { collection, doc, Timestamp, writeBatch } from "firebase/firestore";
import { LogDocument } from "../types";

export const addDocument = async (
  collName: string,
  data: {},
  loggingData: LogDocument
) => {
  const batch = writeBatch(db);
  const now = new Date();
  const logDocRef = doc(db, logging_collection_name, now.toISOString());
  const docRef = doc(collection(db, collName));
  const userObject = {
    uid: loggingData.user.uid,
    email: loggingData.user.email,
    displayName: loggingData.user.displayName,
  };
  try {
    batch.set(docRef, data);
    batch.set(logDocRef, {
      ...loggingData,
      user: userObject,
      item: docRef,
      timestamp: Timestamp.fromDate(now),
    });
  } catch (error) {
    console.error("Error adding to WriteBatch: ", error);
  }
  try {
    await batch.commit();
  } catch (error) {
    console.error("Error updating document: ", error);
  }
};

export const updateDocument = async (
  collName: string,
  docId: string,
  data: {},
  loggingData: LogDocument
) => {
  const batch = writeBatch(db);
  const now = new Date();
  const logDocRef = doc(db, logging_collection_name, now.toISOString());
  const docRef = doc(db, collName, docId);
  const userObject = {
    uid: loggingData.user.uid,
    email: loggingData.user.email,
    displayName: loggingData.user.displayName,
  };
  try {
    batch.update(docRef, data);
    batch.set(logDocRef, {
      ...loggingData,
      user: userObject,
      item: docRef,
      timestamp: Timestamp.fromDate(now),
    });
  } catch (error) {
    console.error("Error adding to WriteBatch: ", error);
  }
  try {
    await batch.commit();
  } catch (error) {
    console.error("Error updating document: ", error);
  }
};

export const deleteDocument = async (
  collName: string,
  docId: string,
  loggingData: LogDocument
) => {
  const batch = writeBatch(db);
  const now = new Date();
  const logDocRef = doc(db, logging_collection_name, now.toISOString());
  const docRef = doc(db, collName, docId);
  const userObject = {
    uid: loggingData.user.uid,
    email: loggingData.user.email,
    displayName: loggingData.user.displayName,
  };
  try {
    batch.delete(docRef);
    batch.set(logDocRef, {
      ...loggingData,
      user: userObject,
      item: docRef,
      timestamp: Timestamp.fromDate(now),
    });
  } catch (error) {
    console.error("Error adding to WriteBatch: ", error);
  }
  try {
    await batch.commit();
  } catch (error) {
    console.error("Error updating document: ", error);
  }
};
