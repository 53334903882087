import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { NetworkProvider } from "./context/NetworkContext";
import { InventoryProvider } from "./context/InventoryContext";
import { AuthProvider } from "./context/AuthContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <NetworkProvider>
        <InventoryProvider>
          <App />
        </InventoryProvider>
      </NetworkProvider>
    </AuthProvider>
  </React.StrictMode>
);

serviceWorkerRegistration.register();

reportWebVitals(console.log);
