import exportFromJSON from "export-from-json";
import { type InventoryItem } from "./types";

export const downloadData = (inventoryData: InventoryItem[]) => {
  const exportData = inventoryData.map((item) => {
    const { docId, amount_with_unit, ...rest } = item;
    const conditions = rest.condition?.join(", ");
    return { ...rest, condition: conditions };
  });
  const fields = {
    name: "Név",
    sector: "Szektor",
    container: "Tároló",
    amount: "Mennyiség",
    unit: "Mértékegység",
    size: "Méret",
    trait: "Jellemző",
    condition: "Állapot",
    category: "Kategória",
    comment: "Megjegyzés",
    checked_out: "Kiadva",
  };
  const fileName = "443_leltar_export";
  const exportType = "csv";
  exportFromJSON({ data: exportData, fileName, exportType, fields });
};

export const logItemSources = {
  log_src_qa_io: "quick_action_inout",
  log_src_qa_del: "quick_action_delete",
  log_src_edit: "edit_page",
  log_src_new: "new_item_page",
};
