const config = {
  apiKey: "AIzaSyCHDLewAjrHFnF2UKU03AZ8k35UZHplROM",
  authDomain: "leltar-443.firebaseapp.com",
  projectId: "leltar-443",
  storageBucket: "leltar-443.appspot.com",
  messagingSenderId: "594268057825",
  appId: "1:594268057825:web:f07282e25229128f5187a0",
};

export function getFirebaseConfig() {
  if (!config || !config.apiKey) {
    throw new Error("No Firebase configuration object provided.");
  } else {
    return config;
  }
}
