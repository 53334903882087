import { Container, Typography } from "@mui/material";
import HomeTabs from "../components/HomeTabs";

const Home = () => {
  return (
    <Container
      maxWidth="md"
      sx={{
        padding: 5,
      }}
    >
      <Typography textAlign="center" variant="h1" fontSize={60}>
        Faház Leltár
      </Typography>
      <HomeTabs />
    </Container>
  );
};

export default Home;
