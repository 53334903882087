import { createContext, ReactNode, useState } from "react";

interface Props {
  children?: ReactNode;
}

interface NetworkContextModel {
  dbOffline: boolean;
  setDbOffline: (_dbOffline: boolean) => void;
  dbLoading: boolean;
  setDbLoading: (_dbLoading: boolean) => void;
}

export const NetworkContext = createContext<NetworkContextModel>(
  {} as NetworkContextModel
);

export const NetworkProvider = ({ children }: Props) => {
  const [dbOffline, setDbOffline] = useState(true);
  const [dbLoading, setDbLoading] = useState(true);

  const value = {
    dbOffline,
    setDbOffline,
    dbLoading,
    setDbLoading,
  };
  return (
    <NetworkContext.Provider value={value}>{children}</NetworkContext.Provider>
  );
};
