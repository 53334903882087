import {
  Box,
  Button,
  Collapse,
  IconButton,
  Palette,
  Typography,
} from "@mui/material";
import { InventoryItem } from "../types";
import { deleteDocument, updateDocument } from "../database/write";
import { inventory_collection_name } from "../firebase/firebase";
import { useContext, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { NetworkContext } from "../context/NetworkContext";
import DeleteIcon from "@mui/icons-material/Delete";
import { logItemSources } from "../lib";
import ConfirmationDialog from "./ConfirmationDialog";

const ListExpandedContent = ({
  openIndex,
  index,
  item,
  palette,
  onDetClick,
  onEditClick,
}: {
  openIndex: number;
  index: number;
  item: InventoryItem;
  palette: Palette;
  onDetClick: () => void;
  onEditClick: () => void;
}) => {
  const { dbOffline } = useContext(NetworkContext);
  const { currentUser } = useContext(AuthContext);

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [itemSelectedToDelete, setItemSelectedToDelete] =
    useState<InventoryItem | null>(null);

  const toggleItemCheckout = (checked_out: boolean) => {
    if (currentUser) {
      updateDocument(
        inventory_collection_name,
        item.docId,
        { checked_out },
        {
          source: logItemSources.log_src_qa_io,
          item_status: checked_out ? "out" : "in",
          user: currentUser,
          changes: [
            { field: "checked_out", old: !checked_out, new: checked_out },
          ],
        }
      ).catch((e) => {
        console.error(e);
      });
    }
  };

  const deleteConfirmedCallback = () => {
    const item = itemSelectedToDelete;
    setOpenDeleteConfirmation(false);
    setItemSelectedToDelete(null);
    if (currentUser && item) {
      deleteDocument(inventory_collection_name, item.docId, {
        source: logItemSources.log_src_qa_del,
        item_status: item.checked_out ? "out" : "in",
        user: currentUser,
        deletedItemData: {
          name: item.name,
          sector: item.sector,
          amount: item.amount,
          unit: item.unit,
          comment: item.comment,
        },
      }).catch();
    }
  };

  const deleteButtonCallback = (item: InventoryItem) => {
    setItemSelectedToDelete(item);
    setOpenDeleteConfirmation(true);
  };

  return (
    <Collapse in={openIndex === index} timeout="auto" unmountOnExit>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 2,
          gap: 2,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            fontSize: { sm: 30 },
          }}
        >
          <Box>
            <Typography fontWeight="bold" fontSize="inherit">
              tároló:
            </Typography>
            <Typography fontStyle="italic" fontSize={{ xs: 13, sm: 20 }}>
              {item.container}
            </Typography>
          </Box>
          <Box>
            <Typography fontWeight="bold" fontSize="inherit">
              mennyiség:
            </Typography>
            <Typography fontStyle="italic" fontSize={{ xs: 13, sm: 20 }}>
              {item.amount_with_unit}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Typography fontWeight="bold">állapot:</Typography>&nbsp;
          <Typography fontStyle="italic">
            {item.condition?.join(", ")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 3,
            marginY: 2,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography fontSize="0.95em">Bent van(nak)?</Typography>
            <Typography fontSize="0.78em">Gombnyomással ki/be</Typography>
          </Box>
          <Button
            disabled={dbOffline}
            onClick={() => {
              toggleItemCheckout(!item.checked_out);
            }}
            size="medium"
            variant="contained"
            color={item.checked_out ? "error" : "success"}
            sx={{
              color: palette.text.primary,
              fontWeight: "bold",
            }}
          >
            {!item.checked_out ? "igen" : "nem"}
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1,
          }}
        >
          <Button variant="contained" color="info" onClick={onDetClick}>
            Részletek
          </Button>
          <Button
            disabled={dbOffline}
            variant="contained"
            color="warning"
            onClick={onEditClick}
          >
            Szerkeszt
          </Button>
        </Box>
        <IconButton
          onClick={() => {
            deleteButtonCallback(item);
          }}
          color="error"
          size="large"
        >
          <DeleteIcon fontSize="large" />
        </IconButton>
        <ConfirmationDialog
          open={openDeleteConfirmation}
          setOpen={setOpenDeleteConfirmation}
          onConfirm={deleteConfirmedCallback}
          title="Törlés megerősítése"
          text={`Biztosan törlöd a ${itemSelectedToDelete?.name} tételt?`}
          cancelText="Mégsem"
          confirmText="Törlés"
        />
      </Box>
    </Collapse>
  );
};

export default ListExpandedContent;
