import { Typography } from "@mui/material";

const NotFound = () => {
  return (
    <Typography
      padding={{ xs: 5, md: 10 }}
      textAlign="center"
      variant="h1"
      fontSize={{ xs: 34, sm: 50, md: 70 }}
    >
      Nincs ilyen oldal
    </Typography>
  );
};

export default NotFound;
