import { AppBar, Box, Container, IconButton, Toolbar } from "@mui/material";
import { useContext } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import { AuthContext } from "../../context/AuthContext";
import SyncIcon from "@mui/icons-material/Sync";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import DownloadIcon from "@mui/icons-material/Download";
import { NetworkContext } from "../../context/NetworkContext";
import NavDesktop from "./NavDesktop";
import NavMobile from "./NavMobile";
import { type PageList } from "../../types";
import { downloadData } from "../../lib";
import { InventoryContext } from "../../context/InventoryContext";

const NavBar = () => {
  const { dbLoading, dbOffline } = useContext(NetworkContext);
  const { currentUser, logOut } = useContext(AuthContext);
  const { inventory } = useContext(InventoryContext);

  const pageList: PageList = [
    {
      order: 1,
      name: "Teljes lista",
      path: "/list",
    },
    {
      order: 2,
      name: "Kintlevők",
      path: "/checked_out",
    },
  ];

  return (
    <AppBar color="default" position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <NavDesktop pageList={pageList} />
          <NavMobile pageList={pageList} />
          <IconButton
            disabled={dbLoading || dbOffline}
            onClick={() => downloadData(inventory)}
          >
            <DownloadIcon />
          </IconButton>
          {dbLoading ? (
            <SyncIcon color="primary" />
          ) : dbOffline ? (
            <CloudOffIcon color="error" />
          ) : (
            <CloudDoneIcon color="success" />
          )}
          {currentUser && (
            <Box sx={{ flexGrow: 0 }}>
              <IconButton onClick={logOut} aria-label="kijelentkezes">
                <LogoutIcon />
              </IconButton>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default NavBar;
