import ItemsTableDesktop from "../components/ItemsTableDesktop";
import React, { useContext } from "react";
import { InventoryContext } from "../context/InventoryContext";
import { InventoryItem } from "../types";
import ItemsListMobile from "../components/ItemsListMobile";
import Search from "../components/Search";
import { useLocation } from "react-router-dom";

const ListItems = ({
  pageTitle,
  smallScreen,
  docFilters,
}: {
  pageTitle: string;
  smallScreen: boolean;
  docFilters?: {
    field: keyof InventoryItem;
    value: unknown;
  };
}) => {
  const { inventory } = useContext(InventoryContext);
  const location = useLocation();

  const items = inventory.filter((i) =>
    docFilters ? i[docFilters.field] === docFilters.value : i
  );
  const itemsSortedName = items
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name));
  const itemsSortedSectorContainerName = items.slice().sort((a, b) => {
    const containerA = a.container || "";
    const containerB = b.container || "";
    if (a.sector === b.sector) {
      if (a.container === b.container) {
        return a.name.localeCompare(b.name);
      }
      return containerA.localeCompare(containerB);
    }
    return a.sector.localeCompare(b.sector);
  });

  const itemsFinalSorted =
    location.pathname === "/list"
      ? itemsSortedSectorContainerName
      : itemsSortedName;

  const [scrollToIndexFromSearch, setScrollToIndexFromSearch] =
    React.useState<number>(-1);
  return smallScreen ? (
    <>
      <Search
        items={itemsFinalSorted}
        setScrollToIndex={setScrollToIndexFromSearch}
      />
      <ItemsListMobile
        pageTitle={pageTitle}
        items={itemsFinalSorted}
        scrollToIndexFromSearch={scrollToIndexFromSearch}
      />
    </>
  ) : (
    <ItemsTableDesktop items={itemsFinalSorted} />
  );
};

export default ListItems;
