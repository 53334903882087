import { initializeApp } from "firebase/app";
import {
  browserLocalPersistence,
  getAuth,
  NextOrObserver,
  onAuthStateChanged,
  sendEmailVerification,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
  User,
} from "firebase/auth";
import {
  CACHE_SIZE_UNLIMITED,
  initializeFirestore,
  persistentLocalCache,
} from "firebase/firestore";
import { getFirebaseConfig } from "./firebase-config";

const app = initializeApp(getFirebaseConfig());
const auth = getAuth(app);
export const db = initializeFirestore(app, {
  localCache: persistentLocalCache({ cacheSizeBytes: CACHE_SIZE_UNLIMITED }),
  ignoreUndefinedProperties: true,
});

const testMode = false;

export const inventory_collection_name = testMode
  ? "test_fahaz_leltar"
  : "fahaz_leltar";
export const logging_collection_name = testMode ? "test_logs" : "logs";
export const helper_collection_name = "helper_collection";

export const signInUser = async (email: string, password: string) => {
  if (!email && !password) {
    return null;
  }
  await setPersistence(auth, browserLocalPersistence);
  const userCredential = await signInWithEmailAndPassword(
    auth,
    email,
    password
  );
  const user = userCredential.user;
  if (!user.emailVerified) {
    await sendEmailVerification(user, {
      url: "https://leltar.szlcscs.hu/login",
    });
  }
  return userCredential;
};

export const userStateListener = (callback: NextOrObserver<User>) => {
  return onAuthStateChanged(auth, callback);
};

export const signOutUser = async () => await signOut(auth);
