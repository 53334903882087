import { Navigate, Outlet, useLocation } from "react-router-dom";
import Loading from "../components/Loading";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { User } from "firebase/auth";

export const AuthenticatedRoute = ({ user }: { user: User | null }) => {
  const { loading } = useContext(AuthContext);
  const location = useLocation();
  if (loading) {
    return <Loading />;
  }
  return user ? (
    <Outlet />
  ) : (
    <Navigate
      to={`/login?redirect=${encodeURIComponent(
        location.pathname + location.search
      )}`}
    />
  );
};

export const UnauthenticatedRoute = ({ user }: { user: User | null }) => {
  const { loading } = useContext(AuthContext);
  if (loading) {
    return <Loading />;
  }
  return !user ? <Outlet /> : <Navigate to="/" />;
};
