import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { type PageList } from "../../types";
import { Box, Button } from "@mui/material";
import { useContext } from "react";

const NavDesktop = ({ pageList }: { pageList: PageList }) => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  return (
    <>
      <Typography
        variant="h6"
        noWrap
        sx={{
          display: { xs: "none", md: "flex" },
          mr: 2,
          fontWeight: 700,
          letterSpacing: ".3rem",
          color: "inherit",
          textDecoration: "none",
          cursor: "pointer",
        }}
        onClick={() => {
          navigate("/");
        }}
      >
        Kezdőlap
      </Typography>
      <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
        {currentUser &&
          pageList.map((page) => (
            <Button
              key={page.name}
              onClick={() => {
                navigate(page.path);
              }}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              {page.name}
            </Button>
          ))}
      </Box>
    </>
  );
};

export default NavDesktop;
