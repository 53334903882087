import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  Input,
  InputLabel,
  Select,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useContext, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { AuthContext } from "../context/AuthContext";
import { NetworkContext } from "../context/NetworkContext";
import { InventoryContext } from "../context/InventoryContext";
import { InventoryItem, LogChanges } from "../types";
import { addDocument } from "../database/write";
import { inventory_collection_name } from "../firebase/firebase";
import { logItemSources } from "../lib";

const New = () => {
  const { dbOffline } = useContext(NetworkContext);
  const { currentUser } = useContext(AuthContext);
  const { fieldOptions } = useContext(InventoryContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { breakpoints } = useTheme();
  const smallScreen = useMediaQuery(breakpoints.down("sm"));
  const largeScreen = useMediaQuery(breakpoints.down("lg"));

  const [name, setName] = useState("");
  const [sector, setSector] = useState<string>("");
  const [container, setContainer] = useState("");
  const [category, setCategory] = useState("");
  const [amount, setAmount] = useState<number>(0);
  const [unit, setUnit] = useState("");
  const [size, setSize] = useState("");
  const [trait, setTrait] = useState("");
  const [condition, setCondition] = useState<string[]>([]);
  const [checked_out, setChecked_out] = useState(false);
  const [comment, setComment] = useState("");

  const onSubmit = (e: any) => {
    e.preventDefault();
    const newItemData: Omit<InventoryItem, "docId"> = {
      name,
      sector,
      container,
      category,
      amount,
      unit,
      size,
      trait,
      condition,
      checked_out,
      comment,
    };
    const changes: LogChanges = Object.keys(newItemData)
      .map((k) => ({
        field: k,
        old: "",
        new: newItemData[k as keyof Omit<InventoryItem, "docId">],
      }))
      .filter((k) => k.old !== k.new);
    if (currentUser) {
      addDocument(inventory_collection_name, newItemData, {
        source: logItemSources.log_src_new,
        item_status: checked_out ? "out" : "in",
        user: currentUser,
        changes: changes,
      })
        .then(() => {
          navigate(
            {
              pathname: searchParams.get("from") || "/",
            },
            { replace: true }
          );
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "3vw",
      }}
    >
      <Typography
        marginBottom={smallScreen ? "3vh" : largeScreen ? "5vh" : "7vh"}
        variant="h1"
        fontSize={{ xs: 30, sm: 40, md: 50 }}
      >
        Új tétel hozzáadása
      </Typography>
      <form
        onSubmit={onSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: "3vh",
          width: smallScreen ? "90%" : largeScreen ? "60%" : "40%",
        }}
      >
        <FormControl>
          <InputLabel htmlFor="name">Név</InputLabel>
          <Input
            id="name"
            required
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <InputLabel htmlFor="sector">Szektor</InputLabel>
          <Select
            id="sector"
            required
            name="sector"
            value={sector}
            onChange={(e) => setSector(e.target.value)}
          >
            {fieldOptions?.sector?.map((sector) => (
              <MenuItem key={sector} value={sector}>
                {sector}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel htmlFor="container">Tároló</InputLabel>
          <Select
            id="container"
            name="container"
            value={container}
            onChange={(e) => setContainer(e.target.value)}
          >
            {fieldOptions?.container?.map((container) => (
              <MenuItem key={container} value={container}>
                {container}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel htmlFor="category">Kategória</InputLabel>
          <Select
            id="category"
            required
            name="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            {fieldOptions?.category?.map((category) => (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel htmlFor="amount">Mennyiség</InputLabel>
          <Input
            id="amount"
            name="amount"
            type="number"
            value={amount.toString()}
            onChange={(e) => setAmount(parseFloat(e.target.value))}
          />
        </FormControl>
        <FormControl>
          <InputLabel htmlFor="unit">Mértékegység</InputLabel>
          <Select
            id="unit"
            name="unit"
            value={unit}
            onChange={(e) => setUnit(e.target.value)}
          >
            {fieldOptions?.unit?.map((unit) => (
              <MenuItem key={unit} value={unit}>
                {unit}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel htmlFor="size">Méret</InputLabel>
          <Input
            id="size"
            name="size"
            value={size}
            onChange={(e) => setSize(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <InputLabel htmlFor="trait">Jellemző</InputLabel>
          <Input
            id="trait"
            name="trait"
            value={trait}
            onChange={(e) => setTrait(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <InputLabel htmlFor="condition">Állapot</InputLabel>
          <Select
            multiple
            id="condition"
            required
            name="condition"
            value={condition}
            onChange={(e) => {
              const {
                target: { value },
              } = e;
              setCondition(
                typeof value === "string" ? value.split(",") : value
              );
            }}
          >
            {fieldOptions?.condition?.map((condition) => (
              <MenuItem key={condition} value={condition}>
                {condition}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography>Bent</Typography>
          <Switch
            id="checked_out"
            name="checked_out"
            checked={checked_out as boolean}
            onChange={(e) => setChecked_out(e.target.checked)}
          />
          <Typography>Kint</Typography>
        </FormControl>
        <FormControl>
          <InputLabel htmlFor="comment">Megjegyzés</InputLabel>
          <Input
            multiline
            id="comment"
            name="comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </FormControl>

        <Button
          disabled={dbOffline}
          variant="contained"
          color="primary"
          type="submit"
        >
          Mentés
        </Button>
      </form>
    </Box>
  );
};
export default New;
