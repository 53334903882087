import React, { useContext, useEffect } from "react";
import {
  createTheme,
  CssBaseline,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import RouterWithAuthProtection from "./router";
import { BrowserRouter } from "react-router-dom";
import NavBar from "./components/NavBar/NavBar";
import { NetworkContext } from "./context/NetworkContext";
import { listenToCollection } from "./database/read";
import { InventoryContext } from "./context/InventoryContext";
import { AuthContext } from "./context/AuthContext";
import { helper_collection_name } from "./firebase/firebase";

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? "dark" : "light",
          listSelected: {
            dark: "#424242",
            light: "#e0e0e0",
          },
          button: {
            red: "#f44336",
            green: "#4caf50",
          },
        },
      }),
    [prefersDarkMode]
  );

  /*get all items from the database*/
  const { setDbLoading, setDbOffline } = useContext(NetworkContext);
  const { setInventory, setFieldOptions } = useContext(InventoryContext);
  const { currentUser } = useContext(AuthContext);
  useEffect(() => {
    if (!currentUser) {
      return setDbOffline(true);
    }
    const unsubscribe = listenToCollection(
      setInventory,
      setDbLoading,
      setDbOffline
    );
    const unsub_helper = listenToCollection(
      setFieldOptions,
      undefined,
      undefined,
      undefined,
      helper_collection_name
    );
    return () => {
      unsubscribe();
      unsub_helper();
    };
  }, [currentUser, setDbLoading, setDbOffline, setFieldOptions, setInventory]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <NavBar />
        <RouterWithAuthProtection />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
