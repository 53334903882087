import { useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import { Box, Typography } from "@mui/material";
import { useContext } from "react";
import { InventoryContext } from "../context/InventoryContext";
import { InventoryItem } from "../types";

const Details = () => {
  const params = useParams();
  const { inventory } = useContext(InventoryContext);
  const item = inventory.find((item) => item.docId === params.id);

  if (!item) {
    return (
      <Container
        sx={{
          textAlign: "center",
          paddingY: "5vh",
        }}
      >
        <Typography variant="h1" fontSize={40}>
          Nincs ilyen tétel
        </Typography>
      </Container>
    );
  }

  const ItemProp = ({
    propName,
    prop,
  }: {
    propName: string;
    prop: keyof InventoryItem;
  }) => {
    const itemProp =
      prop === "checked_out"
        ? item[prop]
          ? "igen"
          : "nem"
        : item[prop]
        ? item[prop]?.toString()
        : "Nincs megadva";
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Typography fontWeight="bold">{propName}: </Typography>&nbsp;
        <Typography
          color={itemProp === "Nincs megadva" ? "red" : "inherit"}
          fontStyle="italic"
        >
          {itemProp}
        </Typography>
      </Box>
    );
  };

  return (
    <Container
      sx={{
        textAlign: "center",
        paddingY: "5vh",
      }}
    >
      <Typography variant="h1" fontSize={40}>
        {item.name} adatlapja
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          paddingTop: "6vh",
        }}
      >
        <ItemProp propName="szektor" prop="sector" />
        <ItemProp propName="tároló" prop="container" />
        <ItemProp propName="mennyiség" prop="amount_with_unit" />
        <ItemProp propName="méret" prop="size" />
        <ItemProp propName="jellemző" prop="trait" />
        <ItemProp propName="állapot" prop="condition" />
        <ItemProp propName="kategória" prop="category" />
        <ItemProp propName="megjegyzés" prop="comment" />
        <ItemProp propName="kikölcsönözve" prop="checked_out" />
      </Box>
    </Container>
  );
};

export default Details;
