import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { InventoryItem } from "../types";

const ItemsTableDesktop = ({ items }: { items: any[] }) => {
  const columns: GridColDef[] = [
    { field: "checked_out", headerName: "Kint/Bent", flex: 1 },
    { field: "name", headerName: "Név", flex: 1 },
    { field: "sector", headerName: "Szektor", flex: 1 },
    { field: "container", headerName: "Tároló", flex: 1 },
    { field: "amount_with_unit", headerName: "Mennyiség", flex: 1 },
    { field: "size", headerName: "Méret", flex: 1 },
    { field: "trait", headerName: "Jellemző", flex: 1 },
    { field: "condition", headerName: "Állapot", flex: 1 },
    { field: "comment", headerName: "Megjegyzés", flex: 1 },
    { field: "category", headerName: "Kategória", flex: 1 },
  ];
  const rows: InventoryItem[] = items.map((i) => ({
    ...i,
    checked_out: i.checked_out ? "kint" : "bent",
  }));
  return (
    <DataGrid
      getRowId={(row) => row.docId}
      columns={columns}
      rows={rows}
      autoHeight
      localeText={{
        noRowsLabel: "Nincs megjeleníthető tétel",
      }}
    />
  );
};

export default ItemsTableDesktop;
