import { User, type UserCredential } from "firebase/auth";
import {
  signInUser,
  signOutUser,
  userStateListener,
} from "../firebase/firebase";
import { createContext, ReactNode, useEffect, useState } from "react";

interface Props {
  children?: ReactNode;
}

interface AuthContextModel {
  loading: boolean;
  currentUser: User | null;
  setCurrentUser: (_user: User) => void;
  isAdmin: boolean;
  logIn: (email: string, password: string) => Promise<UserCredential | null>;
  logOut: () => void;
}

export const AuthContext = createContext<AuthContextModel>(
  {} as AuthContextModel
);

export const AuthProvider = ({ children }: Props) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = userStateListener((user) => {
      if (user) {
        user.getIdToken(true).then(() => {
          user.getIdTokenResult().then((idTokenResult) => {
            const { admin }: any = idTokenResult.claims.app_roles;
            if (!!admin) {
              setIsAdmin(true);
            }
          });
        });
        setCurrentUser(user);
        setLoading(false);
      } else {
        setCurrentUser(null);
        setLoading(false);
      }
    });
    return () => unsubscribe();
  }, [setCurrentUser]);

  const logIn = async (email: string, password: string) => {
    return await signInUser(email, password);
  };

  const logOut = () => {
    signOutUser().catch((e) => {
      console.error(e);
    });
    setCurrentUser(null);
  };

  const value = {
    loading,
    currentUser,
    setCurrentUser,
    isAdmin,
    logIn,
    logOut,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
