import { Box, Button, ListItemText, Typography, useTheme } from "@mui/material";
import Container from "@mui/material/Container";
import { InventoryItem } from "../types";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import { useContext, useEffect, useRef, useState } from "react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import ListExpandedContent from "./ListExpandedContent";
import { InventoryContext } from "../context/InventoryContext";

const ItemsListMobile = ({
  pageTitle = null,
  items,
  scrollToIndexFromSearch,
}: {
  pageTitle?: string | null;
  items: InventoryItem[];
  scrollToIndexFromSearch?: number;
}) => {
  const { fieldOptions } = useContext(InventoryContext);
  const { palette } = useTheme();
  const navigate = useNavigate();
  const virtuoso = useRef<VirtuosoHandle>(null);
  const [openIndex, setOpenIndex] = useState<number>(-1);

  useEffect(() => {
    if (scrollToIndexFromSearch !== undefined) {
      virtuoso.current?.scrollToIndex({
        index: scrollToIndexFromSearch,
        align: "start",
        behavior: "auto",
      });
      setOpenIndex(scrollToIndexFromSearch);
    }
  }, [scrollToIndexFromSearch]);

  //TODO: hash based navigation

  /*useEffect(() => {
    if (window.location.hash) {
      const hash = window.location.hash.substring(1);
      const item = items.find((item) => item.docId === hash);
      if (item) {
        const index = items.indexOf(item);
        if (virtuoso && virtuoso.current) {
          virtuoso.current.scrollToIndex({
            index,
            align: "start",
            behavior: "auto",
          });
          setOpenIndex(index);
        }
      }
    } else {
      setOpenIndex(-1);
    }
  }, [items]);*/

  const onItemClicked = (index: number, item: InventoryItem) => {
    /*window.history.pushState(
      "",
      document.title,
      window.location.pathname + window.location.search + "#" + item.docId
    );*/
    if (virtuoso && virtuoso.current) {
      virtuoso.current.scrollToIndex({
        index,
        align: "start",
        behavior: "auto",
      });
      setOpenIndex(index === openIndex ? -1 : index);
    }
  };

  /*const scrollToTop = () => {
    /!*window.history.pushState(
      "",
      document.title,
      window.location.pathname + window.location.search
    );*!/
    if (virtuoso && virtuoso.current) {
      virtuoso.current.scrollToIndex({
        index: 0,
        align: "start",
        behavior: "auto",
      });
      setOpenIndex(-1);
    }
  };*/

  return (
    <Container sx={{ padding: pageTitle ? 5 : 0 }}>
      {pageTitle && (
        <Typography textAlign="center" fontSize={50} variant="h1">
          {pageTitle}
        </Typography>
      )}
      {/*<Box textAlign="center" margin={pageTitle ? 3 : 0}>
        <Button variant="contained" onClick={scrollToTop}>
          Tetejére
        </Button>
      </Box>*/}
      <Box textAlign="center" margin={pageTitle ? 3 : 0}>
        <Button
          variant="contained"
          onClick={() => {
            navigate("/new");
          }}
        >
          Új hozzáadása
        </Button>
      </Box>
      <Virtuoso
        ref={virtuoso}
        style={{
          height: "65vh",
          marginTop: "3vh",
        }}
        data={items}
        itemContent={(index, item) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: 2,
              backgroundColor:
                openIndex === index
                  ? palette.listSelected[palette.mode]
                  : "transparent",
            }}
            key={item?.docId}
          >
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <ListItemText
                sx={{
                  flex: 4,
                }}
              >
                {item?.name}
              </ListItemText>
              <ListItemText
                sx={{
                  flex: 1,
                }}
              >
                {item?.sector}
              </ListItemText>
              {openIndex === index ? (
                <ExpandLess
                  onClick={() => {
                    onItemClicked(index, item);
                  }}
                />
              ) : (
                <ExpandMore
                  onClick={() => {
                    onItemClicked(index, item);
                  }}
                />
              )}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <ListExpandedContent
                openIndex={openIndex}
                index={index}
                item={item}
                palette={palette}
                onDetClick={() => {
                  navigate({
                    pathname: `/details/${item.docId}`,
                    search: `?from=${window.location.pathname}`,
                  });
                }}
                onEditClick={() => {
                  navigate(
                    {
                      pathname: "/edit",
                      search: `?from=${window.location.pathname}`,
                    },
                    { state: { item, fieldOptions } }
                  );
                }}
              />
            </Box>
          </Box>
        )}
      />
    </Container>
  );
};

export default ItemsListMobile;
