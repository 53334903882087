import { FieldOptions, HelperCollection, InventoryItem } from "../types";
import { createContext, ReactNode, useState } from "react";

interface Props {
  children?: ReactNode;
}

interface InventoryContextModel {
  inventory: InventoryItem[];
  setInventory: (_inventory: InventoryItem[]) => void;
  helperDocs: HelperCollection;
  setHelperDocs: (_helperDocs: HelperCollection) => void;
  fieldOptions: FieldOptions;
  setFieldOptions: (_fieldOptions: FieldOptions) => void;
}

export const InventoryContext = createContext<InventoryContextModel>(
  {} as InventoryContextModel
);

export const InventoryProvider = ({ children }: Props) => {
  const [inventory, setInventory] = useState([] as InventoryItem[]);
  const [helperDocs, setHelperDocs] = useState([] as HelperCollection);
  const [fieldOptions, setFieldOptions] = useState({} as FieldOptions);
  const value = {
    inventory,
    setInventory,
    helperDocs,
    setHelperDocs,
    fieldOptions,
    setFieldOptions,
  };
  return (
    <InventoryContext.Provider value={value}>
      {children}
    </InventoryContext.Provider>
  );
};
