import {
  Box,
  CircularProgress,
  LinearProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const Loading = () => {
  const { breakpoints } = useTheme();
  const smallScreen = useMediaQuery(breakpoints.down("md"));
  return smallScreen ? (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <LinearProgress />
  );
};

export default Loading;
