import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  Input,
  InputLabel,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AuthContext } from "../context/AuthContext";

const Login = () => {
  const { breakpoints } = useTheme();
  const smallScreenLogin = useMediaQuery(breakpoints.down("sm"));
  const largeScreenLogin = useMediaQuery(breakpoints.up("md"));
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get("redirect");
  const navigate = useNavigate();
  const { currentUser, logIn } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onLogin = (e: any) => {
    e.preventDefault();
    logIn(email, password)
      .then(() => {
        navigate(redirect || "/");
      })
      .catch((e) => {
        console.error(e);
      });
  };

  useEffect(() => {
    if (currentUser) {
      navigate("/");
    }
  }, [currentUser, navigate]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "auto",
        padding: "20px",
        rowGap: "3vh",
      }}
    >
      <Typography fontSize={20}>Bejelentkezés a leltárba</Typography>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: "3vh",
          width: smallScreenLogin ? "100%" : largeScreenLogin ? "30%" : "50%",
        }}
      >
        <FormControl>
          <InputLabel htmlFor="email">E-mail cím</InputLabel>
          <Input
            value={email}
            id="email"
            name="email"
            type="email"
            required
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <InputLabel>Jelszó</InputLabel>
          <Input
            value={password}
            id="password"
            name="password"
            type="password"
            required
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormControl>
        <Button variant="contained" onClick={onLogin}>
          Bejelentkezés
        </Button>
      </form>
    </Box>
  );
};

export default Login;
